import React from "react"
import { ThemeProvider } from "styled-components"

const theme = {
  colors: {
    liberty: "#52489C",
    tuftsBlue: "#4062BB",
    seaSerpent: "#59C3C3",
    isabelline: "#EBEBEB",
    fieryRose: "#F45B69",
  },
  fonts: ["sans-serif", "Roboto"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
}

const Theme = props => (
  <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
)

export default Theme
