import React from "react"
import styled from "styled-components"

export const StyledWrapper = styled.div`
  width: 100%;
  padding: 0 16px;

  @media screen and (min-width: 1240px) {
    width: 1240px;
    margin: 0 auto;
    padding: 0;
  }
`

export default function Wrapper(props) {
  return <StyledWrapper>{props.children}</StyledWrapper>
}
