import React from "react"
import styled, { css } from "styled-components"
import Logo from "../../static/images/satws-syntage.svg"

const StyledLogo = styled(Logo)`
  * {
    transition: fill 250ms ease-in-out;
  }

  .big-circle {
    fill: #4062bb;
  }

  .small-circle {
    fill: #97a9da;
  }

  .text-sat {
    fill: #262626;
  }

  .text-ws {
    fill: #7f7f7f;
  }

  ${props =>
    props.white &&
    css`
      * {
        fill: #fff !important;
      }
    `}
`

export default props => <StyledLogo {...props} />
