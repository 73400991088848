import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { StyledWrapper } from "./wrapper"
import Logo from "./logo"
import SignupButton from "./signup-button"

export const ButtonLink = styled.button`
  margin: 0;
  padding: 0;
`

const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  transition-property: background, border-bottom-color, color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;

  ${props =>
    props.active &&
    css`
      background: #fff;
      border-bottom: 1px solid #ebebeb;

      ${MenuLink} {
        color: #4b4b4b;
      }
    `}

  @media screen and (min-width: 1024px) {
    height: auto;
  }
`

export const MenuLogoBox = styled.a`
  width: 120px;

  svg {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 1024px) {
    width: 180px;
  }
`

export const MenuWrapper = styled(StyledWrapper)`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;

  @media screen and (min-width: 1024px) {
    height: 80px;
    padding: 0 inherit;
  }
`

export const MenuActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

export const MenuWrapper2 = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 0 16px;
  position: fixed;
  top: 70px;
  left: 16px;
  right: 16px;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
  display: none;

  ${props =>
    props.open &&
    css`
      display: block;
    `}

  @media screen and (min-width: 1024px) {
    height: 80px;
    padding: 0;
    position: inherit;
    display: block;
    width: 100%;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20%;
    box-shadow: none;
  }
`

export const MenuList = styled.ul`
  padding: 0;
  margin: 0 0 40px 0;
  list-style-type: none;

  @media screen and (min-width: 1024px) {
    display: flex;
    margin: 0;
  }
`
export const MenuListItem = styled.li`
  border-bottom: 1px solid #dedcdc;

  @media screen and (min-width: 1024px) {
    border-bottom: none;
  }
`

export const MenuLink = styled.span`
  /* color: red; */
  border: none;
  font-size: 16px;
  padding: 16px 0;
  margin: 0;
  display: block;
  color: #4b4b4b;
  outline: none;
  background: transparent;
  cursor: pointer;
  text-decoration: none;

  @media screen and (min-width: 1024px) {
    color: #fff;
    margin-right: 48px;
  }
`

export const LinkLogin = styled(MenuLink)`
  @media screen and (min-width: 1024px) {
    margin-right: 24px;
  }
`

export const MenuBurger = styled.button`
  border: none;
  position: relative;
  height: 34px;
  width: 36px;
  margin: 0;
  padding: 0;
  background: transparent;
  display: inline-flex;
  flex-direction: column-reverse;
  justify-content: space-around;

  span {
    width: 35px;
    height: 2px;
    background: #fff;

    display: inline-block;

    ${props =>
      props.active &&
      css`
        background: #4b4b4b;
      `}
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }
`

const SatwsIsSyntageComponent = styled.div`
  color: #fff;
  text-align: center;
  padding: 8px 16px;
  background: #41abad;
  font-size: 16px;

  a {
    color: #fff;
  }
`

function SatwsIsSyntage() {
  return (
    <SatwsIsSyntageComponent>
      Satws evoluciona a una nueva marca: Syntage. 🎉 Conoce más en nuestro{" "}
      <a
        href="https://syntage.com/es/blog/somos-syntage?utm_source=top-page&utm_id=syntage-satws"
        target="_blank"
      >
        blog
      </a>
      .
    </SatwsIsSyntageComponent>
  )
}

export default function Menu() {
  const [active, setActive] = useState(false)
  const [open, setOpen] = useState(false)

  const handleScroll = () =>
    setActive(Math.abs(document.body.getBoundingClientRect().top) > 50)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [active])

  useEffect(handleScroll, [])

  return (
    <StyledNav active={active}>
      <SatwsIsSyntage />
      <MenuWrapper>
        <MenuLogoBox href="/">
          <Logo white={!active} />
        </MenuLogoBox>
        <MenuWrapper2 open={open}>
          <MenuList>
            <MenuListItem>
              <MenuLink as="a" href="/#productos">
                Productos
              </MenuLink>
            </MenuListItem>
            <MenuListItem>
              <MenuLink as="a" href="https://syntage.com/docs/api/">
                Documentación
              </MenuLink>
            </MenuListItem>
            <MenuListItem>
              <MenuLink as="a" href="https://support.syntage.com">
                Soporte
              </MenuLink>
            </MenuListItem>
            <MenuListItem>
              <MenuLink as="a" href="https://syntage.com/contacto">
                Contacto
              </MenuLink>
            </MenuListItem>
          </MenuList>
          <MenuActions>
            <LinkLogin as="a" href="https://app.syntage.com">
              Login
            </LinkLogin>
            <SignupButton />
          </MenuActions>
        </MenuWrapper2>
        <div>
          <MenuBurger
            active={active}
            onClick={() => {
              setOpen(!open)
            }}
          >
            <span />
            <span />
            <span />
          </MenuBurger>
        </div>
      </MenuWrapper>
    </StyledNav>
  )
}
