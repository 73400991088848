import styled, { css } from "styled-components"

export const Button = styled.button`
  padding: 12px 24px;
  background-color: ${props => props.theme.colors.fieryRose};
  color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  text-decoration: none;

  ${props =>
    props.large &&
    css`
      padding: 12px 32px;
      font-size: 18px;
    `}

  &:hover {
    cursor: pointer;
  }
`
