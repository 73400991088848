import React, { useState, useEffect } from "react"
import { Button } from "./button"

const SignupButton = props => {
  const [url, setUrl] = useState("https://app.syntage.com/register")

  useEffect(() => {
    const queryString =
      typeof window !== "undefined" ? window.location.search : ""

    if (queryString) {
      setUrl(url + queryString)
    }
  }, [])

  return (
    <Button as="a" href={url} {...props}>
      Crear Cuenta
    </Button>
  )
}

export default SignupButton
