import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *:after {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }


  button,
  input {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 300;
    outline: none;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 300;
    color: #4B4B4B;
  }
`
