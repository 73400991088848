import React from "react"
import { Helmet } from "react-helmet"
import Menu from "./menu"
import { GlobalStyle } from "./global-styles"
import Theme from "./theme"
import Footer from "./footer"

const title = "Integración con el SAT | Web Services y APIs"
const description =
  "Usa el poder de los datos fiscales en tu negocio. APIs potentes que te permiten conectar tus sistemas con toda la información del SAT."
const url = "https://sat.ws"
const image = "https://sat.ws/images/banner.png"

export default props => (
  <Theme>
    <Helmet>
      <title>{`Satws | ${props.title || title}`}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
    </Helmet>
    <GlobalStyle />
    <Menu />
    {props.children}
    <Footer />
  </Theme>
)
